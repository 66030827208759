import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import firebase from 'firebase';

import AllotoneHeader from '../views/AllotoneHeader';

const styles = theme => ({
  main: {
    paddingBottom: theme.spacing(1),
    overflow: 'scroll',
  },
  container: {
    paddingTop: 'calc(64px + 8px)', // header is 64px on desktop, 56 on mobile
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
    overflowX: 'hidden',
    maxWidth: '400px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  mainText: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  textField: {
    marginBottom: theme.spacing(4),
    width: '90%',
  },
});

class PurchaseCompletePage extends Component {
  state = {
    step: 'initial',
    email: '',
  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }


  render() {
    const classes = this.props.classes;

    return (
      <div className={classes.main}>
        <AllotoneHeader
          title='Allotone Waitlist'
          breadcrumbs={[
            {title: 'Allotone'}
          ]}

        />
        <div className={classes.container}>
            <Typography className={classes.mainText} variant='h5'>Purchase Successful</Typography>
            <Typography gutterBottom variant='body1'>Thank you! It typically takes 24 hours to generate a report from a native speaker.</Typography>
            <Typography className={classes.mainText} variant='body1'>If you think a friend could benefit from Allotone, please share!</Typography>
        </div>
      </div>
    );
  }
}


PurchaseCompletePage.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withStyles(styles)(PurchaseCompletePage);
