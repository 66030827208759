import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import IconButton from '@material-ui/core/IconButton';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';

//import { OnSnapshotController } from '@dokibo/react-firebase-helpers';
import OnSnapshotController from '../components/OnSnapshotController';

import firebase from 'firebase';

const styles = theme => ({
  row: {

  },
});

class GuessRow extends Component {
  state = {
    guess: '',
  }

  handleChange = (dbSet, field) => event => {
    console.log('changing', field, 'to', event.target.value);

    dbSet({
      [field]: event.target.value,
    }, {merge: true})

    this.props.onChange && this.props.onChange(event.target.value);
  }

  handleToggleButtonChange = (dbSet, field) => (event, newValue) => {
    console.log('changing', field, 'to', newValue);
    console.log(dbSet);


    dbSet({
      [field]: newValue,
    }, {merge: true})

    this.props.onChange && this.props.onChange(newValue);
  }

  render() {
    const classes = this.props.classes;

    return (
      <React.Fragment>
        <IconButton onClick={this.props.onPlayClicked} size='small' className={classes.button} aria-label="play">
          <PlayCircleFilledIcon />
        </IconButton>
        <OnSnapshotController
          key={this.props.dbRef.path}
          dbRef={this.props.dbRef}
        >
        {data => (data && data.localData !== undefined) &&
          <React.Fragment>
            <TextField
               label="pinyin"
               className={classes.textField}
               value={data.localData['guess'] || ''}
               onChange={this.handleChange(data.set, 'guess')}
               margin="dense"
               variant="outlined"
             />
             <ToggleButtonGroup
                value={data.localData['soundsLike'] || ''}
                exclusive
                size="small"
                onChange={this.handleToggleButtonChange(data.set, 'soundsLike')}
                aria-label="how close to native this sounded"
              >
              <ToggleButton value="beginner" aria-label="beginner">
                Beginner
              </ToggleButton>
              <ToggleButton value="intermediate" aria-label="intermediate">
                Intermediate
              </ToggleButton>
              <ToggleButton value="native" aria-label="native">
                Native
              </ToggleButton>
            </ToggleButtonGroup>
          </React.Fragment>
          || null
        }
        </OnSnapshotController>
      </React.Fragment>
    );
  }
}


GuessRow.propTypes = {
  classes: PropTypes.object.isRequired,
  onPlayClicked: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  dbRef: PropTypes.object.isRequired,
};

export default withStyles(styles)(GuessRow);
