import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import {yellow} from '@material-ui/core/colors';

import firebase from 'firebase';

import WzRecorder from '../WzRecorder';
import AllotoneHeader from '../views/AllotoneHeader';
import CheckoutComponent from '../components/CheckoutComponent';
import ChineseLevelQuestion from '../components/ChineseLevelQuestion';
import UploadingVisualization from '../components/UploadingVisualization';
import AudioVisualization from '../components/AudioVisualization';
import emailIsValid from '../helpers/emailIsValid';
import { isiOS, isiOSSafari } from '../helpers/platformQueries';

import generateRandomWords from '../helpers/generateRandomWords';
import formatPinyinForDisplay from '../helpers/formatPinyinForDisplay';
import speak from '../helpers/speak';

/* global FS */

let prodUrl = 'https://us-central1-dokibo-speak-prod.cloudfunctions.net/transcribeAudioSegment';
let devUrl = 'http://localhost:5001/dokibo-speak-prod/us-central1/transcribeAudioSegment';
let url = devUrl;

let quizWords = generateRandomWords(500)
console.log(quizWords);

let animationString = 'cubic-bezier(0.8, -0.22, 0.2, 1.0)';
let animationTime = '0.6s';

const styles = theme => ({
  main: {
    paddingBottom: theme.spacing(1),
    overflow: 'scroll',
  },
  container: {
    paddingTop: 'calc(64px + 8px)', // header is 64px on desktop, 56 on mobile
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
    overflowX: 'hidden',
  },
  mainText: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  subMainText: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
  marginButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  word: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
    border: '1px solid',
    marginTop: theme.spacing(4),
    borderColor: theme.palette.grey[800],
    backgroundColor: theme.palette.grey[200],
  },
  wordThinking: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
    border: '1px solid',
    marginTop: theme.spacing(4),
    borderColor: yellow[800],
    backgroundColor: yellow[100],
  },
  '@keyframes word-complete-celebration': {
    '0%': {
      borderColor: theme.palette.grey[800],
      backgroundColor: theme.palette.grey[200],
      transform: 'scale(1.0)',
    },
    '50%': {
      borderColor: theme.palette.primary.dark,
      backgroundColor: theme.palette.primary.dark,
      transform: 'scale(1.2)',
    },
    '100%': {
      borderColor: theme.palette.primary.dark,
      backgroundColor: theme.palette.primary.light,
      transform: 'scale(1.0)',
    },
  },
  wordComplete: {
    animationName: '$word-complete-celebration',
    animationDuration: '0.3s',
    animationTimingFunction: 'ease-in-out',
    animationFillMode: 'forwards',
    animationIterationCount: '1',

    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
    border: '1px solid',
    marginTop: theme.spacing(4),
    borderColor: theme.palette.primary.dark,
    backgroundColor: theme.palette.primary.light,
  },
  textField: {
    marginBottom: theme.spacing(4),
    width: '90%',
  },
  visualizer: {
    width: '80%',
    height: '200px',
  },
  wordAnimationContainer: {
    position: 'relative',
    width: '300%',
    height: '150px',
    // backgroundColor: theme.palette.primary.light,
  },
  wordContainerLeft: {
    width: '200px',
    position: 'absolute',
    left: '0%',
    transition: `left ${animationTime}`,
    transitionTimingFunction: animationString,
    marginBottom: theme.spacing(4),
  },
  wordContainer: {
    width: '200px',
    position: 'absolute',
    transition: `left ${animationTime}`,
    left: 'calc(50% - 100px)',
    transitionTimingFunction: animationString,
    marginBottom: theme.spacing(4),
  },
  wordContainerRight: {
    width: '200px',
    position: 'absolute',
    transition: `left ${animationTime}`,
    transitionTimingFunction: animationString,
    left: '100%',
    marginBottom: theme.spacing(4),
  },
  '@keyframes progress-timer': {
    '0%': {
      width: '0%',
    },
    '100%': {
      width: '100%',
    },
  },
  wordCountdown: {
    animationName: '$progress-timer',
    animationDuration: '5.0s',
    animationTimingFunction: 'ease-out',
    animationFillMode: 'forwards',
    animationDelay: animationTime,
    animationIterationCount: '1',
    width: '0%',
    height: '3px',
    marginTop: '-2px',
    backgroundColor: theme.palette.primary.dark,
  },
  wordCountdownIndefinite: {
    width: '100%',
    height: '3px',
    marginTop: '-2px',
  },
  dotsContainer: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
  },
  lightDot: {
    width: '8px',
    height: '4px',
    marginLeft: '2px',
    marginRight: '2px',
    borderRadius: '0px',
    backgroundColor: theme.palette.primary.light,
    transition: `background-color ${animationTime}`,
    transitionTimingFunction: animationString,
  },
  darkDot: {
    width: '8px',
    height: '4px',
    marginLeft: '2px',
    marginRight: '2px',
    borderRadius: '0px',
    backgroundColor: theme.palette.primary.dark,
    transition: `background-color ${animationTime}`,
    transitionTimingFunction: animationString,
  },
  '@keyframes animate-in': {
    '0%': {
      opacity: 0,
      paddingTop: '20px',
    },
    '90%': {
      opacity: 0.75,
      paddingTop: '0px',
    },
    '100%': {
      opacity: 1,
      paddingTop: '0px',
    }
  },
  animateIn: {
    animationName: '$animate-in',
    animationDuration: '0.3s',
    animationTimingFunction: 'ease-out',
    animationFillMode: 'forwards',
    animationDelay: 0,
    animationIterationCount: '1',
  },
  '@keyframes animate-out': {
    '0%': {
      opacity: 1,
      paddingTop: '0px',
    },
    '100%': {
      opacity: 0,
      paddingTop: '-20px',
    },
  },
  animateOut: {
    animationName: '$animate-out',
    animationDuration: '0.3s',
    animationTimingFunction: 'ease-in',
    animationFillMode: 'forwards',
    animationDelay: 0,
    animationIterationCount: '1',
  },
});

class SpeakingPracticePage extends Component {
  state = {
    step: 'initial',
    wordPromptTimes: [],
    wordCompleteStatus: 'not-spoken',
    currentWord: 0,
    currentWordTranscription: false,
    userTries: 0,
    loudnessThresholdSurpassed: false,
    sessionTimerComplete: false,
  }

  constructor(props) {
    super(props);

    this.recorder = new WzRecorder({
      streaming: false,
      bufferSize: 4096,
      onRecordingStop: this.handleRecordingStop,
      onRecording: this.handleRecordingTick,
    });

    this.currentWordElementRef = React.createRef();
  }

  componentDidMount() {
    // warm up the firebase function so that the first word experience doesn't suck
    fetch(url, {
      method: 'OPTIONS'
    })
  }

  handleClickStart = () => {
    this.recorder.toggleRecording();
  }

  handleRecordingTick = (milliseconds) => {
    if (this.state.recordingStartedTimestamp === undefined) {
      this.setState({
        recordingStartedTimestamp: new Date(Date.now() - milliseconds),
      })
    }

    if (this.state.step === 'initial') {
      this.setState({
        step: 'ongoing',
        wordPromptTimes: [new Date() - this.state.recordingStartedTimestamp],
        wordCompleteStatus: 'not-spoken',
      });

      setTimeout(this.handleSessionTimerComplete, 10 * 60 * 1000);
      setTimeout(() => {
        this.checkPronunciation();
      }, 5 * 1000);
    }
  }

  handleSessionTimerComplete = () => {
    console.log('handleSessionTimerComplete');

    this.setState({
      sessionTimerComplete: true,
    })
  }

  handleLoudnessThresholdSurpassed = (loundess) => {
    setTimeout(() => {
      if (this.state.wordCompleteStatus === 'not-spoken') {
        this.setState({
          wordCompleteStatus: 'thinking',
          loudnessThresholdSurpassed: true,
        })
      }
    }, 1000);
  }

  wordCompleteClass = () => {
    if (this.state.wordCompleteStatus === 'not-spoken') {
      return this.props.classes.word;
    } else if (this.state.wordCompleteStatus === 'thinking') {
      return this.props.classes.word;
    } else if (this.state.wordCompleteStatus === 'spoken') {
      return this.props.classes.wordComplete;
    }
  }

  advanceToNextWordInternal = () => {
    let timestamp = new Date() - this.state.recordingStartedTimestamp;

    let nextWord = this.state.currentWord + 1;
    if (nextWord === quizWords.length) {
      console.error('reached end of quizWords before timer finished');
      this.setState({
        step: 'finished',
      });
      this.recorder.toggleRecording();
      return;
    }

    if (this.state.sessionTimerComplete === true) {
      this.setState({
        step: 'finished',
      })
      this.recorder.toggleRecording();
      return;
    }

    this.setState({
      wordCompleteStatus: 'not-spoken',
      wordPromptTimes: [...this.state.wordPromptTimes, timestamp],
      currentWord: nextWord,
      currentWordTranscription: false,
      userTries: 0,
    });

    setTimeout(() => {
      this.checkPronunciation();
    }, 5 * 1000);
  }

  handleTryAgain = () => {
    let timestamp = new Date() - this.state.recordingStartedTimestamp;

    this.setState({
      wordCompleteStatus: 'not-spoken',
      wordPromptTimes: [...this.state.wordPromptTimes.slice(0, this.state.wordPromptTimes.length-2), timestamp],
      currentWordTranscription: false,
    });

    // hacky way to restart the animation
    // // from https://stackoverflow.com/questions/6268508/restart-animation-in-css3-any-better-way-than-removing-the-element
    // let element = this.currentWordElementRef.current;
    // element.style.webkitAnimation = 'none';
    // setTimeout(function() {
    //     element.style.webkitAnimation = '';
    // }, 10);

    setTimeout(() => {
      this.checkPronunciation();
    }, 5 * 1000);
  }

  checkPronunciation = () => {
    this.setState({
      wordCompleteStatus: 'thinking',
    })

    let timestamp = new Date() - this.state.recordingStartedTimestamp;

    this.recorder.exportWavSegment(
      this.state.wordPromptTimes[this.state.wordPromptTimes.length-1],
      timestamp,
      (blob) => {
      console.log('wav exported', blob);

      fetch(url, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'audio/wav',
        }),
        body: blob
      })
      .then(response => response.json())
      .then(transcription => {
        console.log('transcription:', transcription)

        if (quizWords[this.state.currentWord].words.includes(transcription.chinese)) {
          this.setState({
            wordCompleteStatus: 'spoken',
          })

          setTimeout(this.advanceToNextWordInternal, 0.5 * 1000);
        } else {
          this.setState({
            currentWordTranscription: transcription,
            userTries: this.state.userTries + 1,
          })
        }
      });
    });
  }


  handleRecordingStop = (blob) => {
  }

  handleClickSpeak = () => {
    return speak(quizWords[this.state.currentWord].words[0], 1, 'zh-CN', 1);
  }


  render() {
    const classes = this.props.classes;
    let iOSNotSafari = isiOS() && !isiOSSafari();

    if (iOSNotSafari) {
      return (
        <div className={classes.main}>
          <AllotoneHeader
            title='Allotone Pronunciation Quiz'
            breadcrumbs={[
              {title: 'Allotone'}
            ]}

          />
          <div className={classes.container}>
            <Typography className={classes.mainText} variant='h6'>Please open allotone.com using the Safari app to continue.</Typography>
            <Typography className={classes.mainText} variant='caption'>Chrome & in-app browsers are not supported on iOS because they're unable to record audio.</Typography>
          </div>
        </div>
      )
    }

    return (
      <div className={classes.main}>
        <AllotoneHeader
          title='Allotone - Speaking Practice'
          breadcrumbs={[
            {title: 'Allotone'}
          ]}

        />
        <div className={classes.container}>
          {this.state.step === 'initial' &&
            <React.Fragment>
              <Typography className={classes.mainText} variant='h5'>Speaking Practice</Typography>
              <Typography gutterBottom variant='p'>Ensure that you're somewhere where you can keep going for at least 10 minutes. The best learning requries uninterrupted focus!</Typography>
              <Button className={classes.marginButton} onClick={this.handleClickStart} variant='contained' color='primary'>Start 10 min Session</Button>
            </React.Fragment>
          }
          {this.state.step === 'ongoing' &&
            <React.Fragment>
              <div className={[classes.dotsContainer, classes.animateIn].join(' ')}>
                {Object.keys(quizWords).map((dot, i) =>
                  i < this.state.currentWord ?
                  <div key={i} className={classes.darkDot} />
                  :
                  <div key={i} className={classes.lightDot} />
                )}
              </div>
              <Typography className={[classes.mainText, classes.animateIn].join(' ')} variant='h5'>Say this word</Typography>
              <div className={[classes.wordAnimationContainer, classes.animateIn].join(' ')}>
                <div key={this.state.currentWord-1} className={classes.wordContainerLeft}>
                  {this.state.currentWord > 0 &&
                    <Typography
                      className={this.wordCompleteClass()}
                      variant='h5'>
                      {formatPinyinForDisplay(quizWords[this.state.currentWord-1])}
                    </Typography>
                  }
                </div>
                <div key={this.state.currentWord} className={classes.wordContainer}>
                  <Typography
                    className={this.wordCompleteClass()}
                    variant='h5'>
                    {formatPinyinForDisplay(quizWords[this.state.currentWord])}
                  </Typography>
                  {this.state.wordCompleteStatus === 'not-spoken' &&
                    <div ref={this.currentWordElementRef} className={classes.wordCountdown} />
                  }
                  {this.state.wordCompleteStatus === 'thinking' && this.state.currentWordTranscription === false &&
                    <LinearProgress color="secondary" className={classes.wordCountdownIndefinite} />
                  }
                </div>
                <div key={this.state.currentWord+1} className={classes.wordContainerRight}>
                  {this.state.currentWord+1 < quizWords.length &&
                    <Typography
                      className={this.wordCompleteClass()}
                      variant='h5'>
                      {formatPinyinForDisplay(quizWords[this.state.currentWord+1])}
                    </Typography>
                  }
                </div>
              </div>
              {this.state.currentWordTranscription !== false &&
                <React.Fragment>
                  <Typography className={classes.mainText} variant='h5'>Allotone guessed</Typography>
                  <Typography
                    className={classes.word}
                    variant='h5'>
                    {this.state.currentWordTranscription.pinyin}
                  </Typography>
                  {this.state.userTries > 1 &&
                    <Button
                      variant='contained'
                      className={classes.marginButton}
                      onClick={this.handleClickSpeak}>
                      Listen
                    </Button>
                  }
                  {quizWords[this.state.currentWord].words.includes(this.state.currentWordTranscription.chinese) ?
                    <Button
                      variant='contained'
                      color='primary'
                      className={classes.marginButton}
                      onClick={this.advanceToNextWordInternal}>
                      Next
                    </Button>
                    :
                    <Button
                      variant='contained'
                      color={this.state.userTries > 2 ? undefined : 'primary'}
                      className={classes.marginButton}
                      onClick={this.handleTryAgain}>
                      Try Again
                    </Button>
                  }
                  {this.state.userTries > 2 &&
                    <Button
                      variant='contained'
                      color='primary'
                      className={classes.marginButton}
                      onClick={this.advanceToNextWordInternal}>
                      Skip
                    </Button>
                  }
                </React.Fragment>
              }
              {this.state.currentWordTranscription === false &&
                <AudioVisualization
                  className={[classes.visualizer, classes.animateIn].join(' ')}
                  getAudioData={this.recorder.mostRecentRecordedChunk} />
              }
            </React.Fragment>
          }
          {this.state.step === 'finished' &&
            <React.Fragment>
              <Typography className={classes.mainText} variant='h5'>You're done, 10 minutes is up!</Typography>
              <Typography className={classes.mainText} variant='body1'>Great job at getting some practice in!</Typography>
            </React.Fragment>
          }
        </div>
      </div>
    );
  }
}


SpeakingPracticePage.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withStyles(styles)(SpeakingPracticePage);
