import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core/styles';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  div: {
    width: '100%',
    maxWidth: '450px',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
  },
  nextButton: {
    marginTop: theme.spacing(2),
  }
});

class ChineseLevelQuestion extends PureComponent {
  state = {
      value: '',
  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  onClickNext = () => {
    this.props.onComplete && this.props.onComplete(this.state.value);
  }

  handleChange = (e) => {
    this.setState({
      value: e.target.value,
    })
  }

  render() {
    let classes = this.props.classes;

    return (
      <div className={classes.div}>
        <Typography className={classes.title} gutterBottom variant="h5">
          Just a few more questions.
        </Typography>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel component="legend">How would you describe your speaking ability?</FormLabel>
          <RadioGroup aria-label="ability" name="ability" value={this.state.value} onChange={this.handleChange}>
            <FormControlLabel value="beginner" control={<Radio />} label="Beginner" />
            <FormControlLabel value="intermediate" control={<Radio />} label="Intermediate" />
            <FormControlLabel value="fluent" control={<Radio />} label="Fluent" />
            <FormControlLabel value="native" control={<Radio />} label="Native" />
          </RadioGroup>
        </FormControl>

        <Button
          className={classes.nextButton}
          variant='contained'
          color='secondary'
          disabled={this.state.value === ''}
          onClick={this.onClickNext}
          >
          Next
        </Button>
      </div>
    )
  }
}

ChineseLevelQuestion.propTypes = {
  onComplete: PropTypes.func,
};

export default withStyles(styles)(withTheme(ChineseLevelQuestion));
