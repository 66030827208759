import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import firebase from 'firebase';

import AllotoneHeader from '../views/AllotoneHeader';
import emailIsValid from '../helpers/emailIsValid';

/* global FS */

const styles = theme => ({
  main: {
    paddingBottom: theme.spacing(1),
    overflow: 'scroll',
  },
  container: {
    paddingTop: 'calc(64px + 8px)', // header is 64px on desktop, 56 on mobile
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
    overflowX: 'hidden',
    maxWidth: '400px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  mainText: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  textField: {
    marginBottom: theme.spacing(4),
    width: '90%',
  },
});

class WaitlistPage extends Component {
  state = {
    step: 'initial',
    email: '',
  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (firebase.auth().currentUser === null) {
      console.log('creating an anonymous user');
      firebase.auth().signInAnonymously().catch((error) => {
        console.log(error.code, error.message);
      });
    }
  }


  handleClickSubmitWaitlist = () => {
    console.log('handleClickSubmitWaitlist');

    if (firebase.auth().currentUser.isAnonymous === true) {
      FS.identify(firebase.auth().currentUser, {
        displayName: 'Anonymous User',
        email: this.state.email,
      });
    }

    var waitlistRef = firebase.firestore()
      .collection('waitlist-allotone').doc()

    waitlistRef.set({
      submittedEmail: this.state.email,
      userId: firebase.auth().currentUser.uid,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    });

    this.setState({
      step: 'thanks',
    })
  }

  handleEmailChange = (element) => {
    this.setState({
      email: element.target.value,
    })
  }

  render() {
    const classes = this.props.classes;

    return (
      <div className={classes.main}>
        <AllotoneHeader
          title='Allotone Waitlist'
          breadcrumbs={[
            {title: 'Allotone'}
          ]}

        />
        <div className={classes.container}>
          {this.state.step === 'initial' &&
            <React.Fragment>
              <Typography className={classes.mainText} variant='h5'>Join The Waitlist</Typography>
              <Typography className={classes.mainText} variant='body1'>
                We've recieved a ton of signups recently and need to hit pause for a moment to get through them.
              </Typography>
              <Typography className={classes.mainText} variant='body1'>
                Enter your email address to be added to the waitlist.
              </Typography>
              <TextField
                id="waitlist-email"
                label="Email"
                type="email"
                name="email"
                autoComplete="email"
                className={classes.textField}
                value={this.state.email}
                onChange={this.handleEmailChange}
                margin="normal"
              />
              <Button
                onClick={this.handleClickSubmitWaitlist}
                disabled={!emailIsValid(this.state.email)}
                variant='contained'
                color='primary'>
                Submit
              </Button>
            </React.Fragment>
          }
          {this.state.step === 'thanks' &&
            <React.Fragment>
              <Typography className={classes.mainText} variant='h5'>You've been added to the Allotone waitlist.</Typography>
              <Typography className={classes.mainText} variant='body1'>
                Thanks! We'll send you an email when we're ready for you.
              </Typography>
              <Typography className={classes.mainText} variant='body1'>
                If you think a friend could benefit from reserving their spot in line, please share!
              </Typography>
            </React.Fragment>
          }
        </div>
      </div>
    );
  }
}


WaitlistPage.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withStyles(styles)(WaitlistPage);
