// Import FirebaseAuth and firebase.
import React from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import AllotoneHeader from '../views/AllotoneHeader';

import queryString from 'query-string'

const styles = theme => ({
  container: {
    paddingTop: 'calc(64px)', // header is 64px
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '1200px',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },
  title: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
})

class SignInScreen extends React.Component {
  state = {
    uiConfig: null,
  }

  componentDidMount() {
    let { from } = this.props.location.state || { from: { pathname: "/" } };
    const values = queryString.parse(this.props.location.search)
    console.log(values);

    var nextUrl = values.next || from.pathname;

    if (values.topic) {
      nextUrl += '?topic=' + values.topic
    }

    var anonymousUser = firebase.auth().currentUser;
    // Configure FirebaseUI.
    this.setState({
      uiConfig: {
        autoUpgradeAnonymousUsers: true,
        // Popup signin flow rather than redirect flow.
        signInFlow: 'popup',
        // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
        signInSuccessUrl:  nextUrl,
        // We will display Google and Facebook as auth providers.
        signInOptions: [
          firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          firebase.auth.EmailAuthProvider.PROVIDER_ID,
        ],
        callbacks: {
          signInSuccessWithAuthResult: (authResult, redirectUrl) => {
            // Process result. This will not trigger on merge conflicts.
            // On success redirect to signInSuccessUrl.
            return true;
          },
          // signInFailure callback must be provided to handle merge conflicts which
          // occur when an existing credential is linked to an anonymous user.
          signInFailure: (error) => {
            // For merge conflicts, the error.code will be
            // 'firebaseui/anonymous-upgrade-merge-conflict'.
            if (error.code != 'firebaseui/anonymous-upgrade-merge-conflict') {
              return Promise.resolve();
            }
            // The credential the user tried to sign in with.
            var cred = error.credential;

            // copy database stuff if needed
            firebase.auth().signInWithCredential(cred)
            .then(anonymousUser.delete)
            .then(() => window.location.assign(nextUrl));
          }
        }
      }
    })
  }

  render() {
    let classes = this.props.classes;

    let topic = queryString.parse(this.props.location.search).topic;

    return this.state.uiConfig && (
      <div>
        <AllotoneHeader
          title='Dokibo | Sign In'
          breadcrumbs={[
            {title: 'Allotone', href: '/'}
          ]}
        />
        <div className={classes.container}>
          <Typography variant='h5' className={classes.title}>Sign In</Typography>
          <Typography variant='body2' className={classes.subtitle}>Create or access your account by choosing one of the options below.</Typography>
          {topic &&
            <Typography variant='body2' className={classes.subtitle}>
              Then get started with your conversation about <span style={{fontWeight: 'bold'}}>{topic}</span>.
            </Typography>
          }
          <StyledFirebaseAuth uiConfig={this.state.uiConfig} firebaseAuth={firebase.auth()}/>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(SignInScreen);
