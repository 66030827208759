import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {yellow} from '@material-ui/core/colors';

import firebase from 'firebase';
import pinyinize from 'pinyinize';

import WzRecorder from '../WzRecorder';
import AllotoneHeader from '../views/AllotoneHeader';
import CheckoutComponent from '../components/CheckoutComponent';
import ChineseLevelQuestion from '../components/ChineseLevelQuestion';
import UploadingVisualization from '../components/UploadingVisualization';
import AudioVisualization from '../components/AudioVisualization';
import emailIsValid from '../helpers/emailIsValid';
import { isiOS, isiOSSafari } from '../helpers/platformQueries';

import generateRandomWords from '../helpers/generateRandomWords';

/* global FS */

let quizWords = generateRandomWords().map(word => pinyinize(word.pinyin).replace(' ', '').replace('5', ''));
console.log(quizWords);

let animationString = 'cubic-bezier(0.8, -0.22, 0.2, 1.0)';
let animationTime = '0.6s';

const styles = theme => ({
  main: {
    paddingBottom: theme.spacing(1),
    overflow: 'scroll',
  },
  container: {
    paddingTop: 'calc(64px + 8px)', // header is 64px on desktop, 56 on mobile
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
    overflowX: 'hidden',
  },
  mainText: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  subMainText: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
  warmupNextButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  word: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
    border: '1px solid',
    marginTop: theme.spacing(4),
    borderColor: theme.palette.grey[800],
    backgroundColor: theme.palette.grey[200],
  },
  wordThinking: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
    border: '1px solid',
    marginTop: theme.spacing(4),
    borderColor: yellow[800],
    backgroundColor: yellow[100],
  },
  '@keyframes word-complete-celebration': {
    '0%': {
      borderColor: theme.palette.grey[800],
      backgroundColor: theme.palette.grey[200],
      transform: 'scale(1.0)',
    },
    '50%': {
      borderColor: theme.palette.primary.dark,
      backgroundColor: theme.palette.primary.dark,
      transform: 'scale(1.2)',
    },
    '100%': {
      borderColor: theme.palette.primary.dark,
      backgroundColor: theme.palette.primary.light,
      transform: 'scale(1.0)',
    },
  },
  wordComplete: {
    animationName: '$word-complete-celebration',
    animationDuration: '0.3s',
    animationTimingFunction: 'ease-in-out',
    animationFillMode: 'forwards',
    animationIterationCount: '1',

    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
    border: '1px solid',
    marginTop: theme.spacing(4),
    borderColor: theme.palette.primary.dark,
    backgroundColor: theme.palette.primary.light,
  },
  textField: {
    marginBottom: theme.spacing(4),
    width: '90%',
  },
  visualizer: {
    width: '80%',
    height: '200px',
  },
  wordAnimationContainer: {
    position: 'relative',
    width: '300%',
    height: '150px',
    // backgroundColor: theme.palette.primary.light,
  },
  wordContainerLeft: {
    width: '200px',
    position: 'absolute',
    left: '0%',
    transition: `left ${animationTime}`,
    transitionTimingFunction: animationString,
    marginBottom: theme.spacing(4),
  },
  wordContainer: {
    width: '200px',
    position: 'absolute',
    transition: `left ${animationTime}`,
    left: 'calc(50% - 100px)',
    transitionTimingFunction: animationString,
    marginBottom: theme.spacing(4),
  },
  wordContainerRight: {
    width: '200px',
    position: 'absolute',
    transition: `left ${animationTime}`,
    transitionTimingFunction: animationString,
    left: '100%',
    marginBottom: theme.spacing(4),
  },
  '@keyframes progress-timer': {
    '0%': {
      width: '0%',
    },
    '100%': {
      width: '100%',
    },
  },
  wordCountdown: {
    animationName: '$progress-timer',
    animationDuration: '5.0s',
    animationTimingFunction: 'ease-out',
    animationFillMode: 'forwards',
    animationDelay: animationTime,
    animationIterationCount: '1',
    width: '0%',
    height: '3px',
    marginTop: '-2px',
    backgroundColor: theme.palette.primary.dark,
  },
  dotsContainer: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
  },
  lightDot: {
    width: '8px',
    height: '4px',
    marginLeft: '2px',
    marginRight: '2px',
    borderRadius: '0px',
    backgroundColor: theme.palette.primary.light,
    transition: `background-color ${animationTime}`,
    transitionTimingFunction: animationString,
  },
  darkDot: {
    width: '8px',
    height: '4px',
    marginLeft: '2px',
    marginRight: '2px',
    borderRadius: '0px',
    backgroundColor: theme.palette.primary.dark,
    transition: `background-color ${animationTime}`,
    transitionTimingFunction: animationString,
  },
  '@keyframes animate-in': {
    '0%': {
      opacity: 0,
      paddingTop: '20px',
    },
    '90%': {
      opacity: 0.75,
      paddingTop: '0px',
    },
    '100%': {
      opacity: 1,
      paddingTop: '0px',
    }
  },
  animateIn: {
    animationName: '$animate-in',
    animationDuration: '0.3s',
    animationTimingFunction: 'ease-out',
    animationFillMode: 'forwards',
    animationDelay: 0,
    animationIterationCount: '1',
  },
  '@keyframes animate-out': {
    '0%': {
      opacity: 1,
      paddingTop: '0px',
    },
    '100%': {
      opacity: 0,
      paddingTop: '-20px',
    },
  },
  animateOut: {
    animationName: '$animate-out',
    animationDuration: '0.3s',
    animationTimingFunction: 'ease-in',
    animationFillMode: 'forwards',
    animationDelay: 0,
    animationIterationCount: '1',
  },
});

class PronunciationQuizPage extends Component {
  state = {
    step: 'initial',
    uploadProgress: 0,
    wordPromptTimes: [],
    wordCompleteStatus: 'not-spoken',
    currentWord: 0,
    email: '',
  }

  constructor(props) {
    super(props);

    this.recorder = new WzRecorder({
      streaming: false,
      bufferSize: 4096,
      onRecordingStop: this.handleRecordingStop,
      onRecording: this.handleRecordingTick,
    });
  }

  componentDidMount() {
    firebase.firestore().collection('metadata').doc('quizzes').get()
    .then(snapshot => {
      let count = snapshot.get('count');
      let waitlistAfter = snapshot.get('waitlistAfter');

      if (count >= waitlistAfter) {
        this.props.history.push('/waitlist');
      }
    });
  }

  handleClickStart = () => {
    this.recorder.toggleRecording();

    if (firebase.auth().currentUser === null) {
      console.log('creating an anonymous user');
      firebase.auth().signInAnonymously().catch((error) => {
        console.log(error.code, error.message);
      });
    }
  }

  handleChineseLevelComplete = (level) => {
    console.log('handleChineseLevelComplete', level);

    this.setState({
      chineseLevel: level,
      step: 'enter-email',
    })

    var quizDocRef = firebase.firestore()
      .collection('quizzes').doc(this.state.quizDocId);

    quizDocRef.set({
      chineseLevel: level,
    }, {merge: true});
  }

  handleClickSubmitEmail = () => {
    console.log('handleClickSubmitEmail');

    if (firebase.auth().currentUser.isAnonymous === true) {
      FS.identify(firebase.auth().currentUser, {
        displayName: 'Anonymous User',
        email: this.state.email,
      });
    }

    var quizDocRef = firebase.firestore()
      .collection('quizzes').doc(this.state.quizDocId);

    quizDocRef.set({
      submittedEmail: this.state.email,
    }, {merge: true})

    if (this.state.audioState === 'uploaded') {
      this.setState({
        step: 'checkout',
      })
    } else {
      this.setState({
        step: 'uploading',
      })
    }
  }

  handleFreeCheckoutComplete = () => {
    this.setState({
      step: 'thanks',
    })
  }

  handleRecordingTick = (milliseconds) => {
    if (this.state.recordingStartedTimestamp === undefined) {
      this.setState({
        recordingStartedTimestamp: new Date(Date.now() - milliseconds),
      })
    }

    if (this.state.step === 'initial') {
      this.setState({
        step: 'warmup',
      });
    }
  }

  handleLoudnessThresholdSurpassed = (loundess) => {
    setTimeout(() => {
      if (this.state.wordCompleteStatus === 'not-spoken') {
        this.setState({
          wordCompleteStatus: 'thinking',
        })
      }
    }, 1000);
  }

  wordCompleteClass = () => {
    if (this.state.wordCompleteStatus === 'not-spoken') {
      return this.props.classes.word;
    } else if (this.state.wordCompleteStatus === 'thinking') {
      return this.props.classes.word;
    } else if (this.state.wordCompleteStatus === 'spoken') {
      return this.props.classes.wordComplete;
    }
  }

  completeWarmupInternal = () => {
    this.setState({
      step: 'ongoing',
      wordPromptTimes: [new Date() - this.state.recordingStartedTimestamp],
      wordCompleteStatus: 'not-spoken',
    });

    setTimeout(() => {
      this.advanceToNextWord();
    }, 5 * 1000);
  }

  completeWarmup = () => {
    this.setState({
      wordCompleteStatus: 'spoken',
    });

    setTimeout(this.completeWarmupInternal, 0.5 * 1000);
  }

  advanceToNextWordInternal = () => {
    let timestamp = new Date() - this.state.recordingStartedTimestamp;

    let nextWord = this.state.currentWord + 1;
    if (nextWord === quizWords.length) {
      this.setState({
        step: 'chinese-level',
      });
      this.recorder.toggleRecording();
      return;
    }

    this.setState({
      wordCompleteStatus: 'not-spoken',
      wordPromptTimes: [...this.state.wordPromptTimes, timestamp],
      currentWord: nextWord,
    });

    setTimeout(() => {
      this.advanceToNextWord();
    }, 5 * 1000);
  }

  advanceToNextWord = () => {
    this.setState({
      wordCompleteStatus: 'spoken',
    })

    setTimeout(this.advanceToNextWordInternal, 0.5 * 1000);
  }

  handleRecordingStop = (blob) => {
    console.log('handleRecordingStop with blob size ', blob.size, this.state.currentMessageId);

    var quizDocRef = firebase.firestore()
      .collection('quizzes').doc()

    this.setState({
      quizDocId: quizDocRef.id,
      audioState: 'uploading,'
    })

    var storageRef = firebase.storage().ref()
      .child('users').child(firebase.auth().currentUser.uid)
      .child('quizzes').child(`${quizDocRef.id}-audio`)

    quizDocRef.set({
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      audioState: 'uploading',
      audioPath: storageRef.fullPath,
      wordPromptTimes: this.state.wordPromptTimes,
      quizWords: quizWords,
      audioDuration: new Date() - this.state.recordingStartedTimestamp,
      userId: firebase.auth().currentUser.uid,
    })
    .then(snapshot => {
      let uploadTask = storageRef.put(blob)
      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
        this.uploadStateChangedObserver,
        this.uploadErrorObserver,
        this.uploadCompletionObserver
      );
    })
  }

  uploadStateChangedObserver = (snapshot) => {
    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
    var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    console.log(`Upload is ${progress}% done, and ${snapshot.state}`);

    this.setState({
      uploadProgress: progress,
    });
  }

  uploadErrorObserver = (error) => {
    var quizDocRef = firebase.firestore()
      .collection('quizzes').doc(this.state.quizDocId);

    quizDocRef.set({
      modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
      error: error.toString(),
    }, {merge: true})
  }

  uploadCompletionObserver = () => {
    this.setState({
      audioState: 'uploaded',
    });

    if (this.state.step === 'uploading') {
      this.setState({
        step: 'checkout',
      });
    }

    var quizDocRef = firebase.firestore()
      .collection('quizzes').doc(this.state.quizDocId);

    quizDocRef.set({
      modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
      audioState: 'uploaded',
    }, {merge: true})
    .then(snapshot => {
      console.log(`finished uploading audio for message`, quizDocRef.id)
    })
    .catch(error => {
      console.log(error);

      quizDocRef.set({
        modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
        error: error.toString(),
      }, {merge: true})
    });
  }

  handleEmailChange = (element) => {
    this.setState({
      email: element.target.value,
    })
  }

  render() {
    const classes = this.props.classes;
    let iOSNotSafari = isiOS() && !isiOSSafari();

    if (iOSNotSafari) {
      return (
        <div className={classes.main}>
          <AllotoneHeader
            title='Allotone Pronunciation Quiz'
            breadcrumbs={[
              {title: 'Allotone'}
            ]}

          />
          <div className={classes.container}>
            <Typography className={classes.mainText} variant='h6'>Please open allotone.com using the Safari app to continue.</Typography>
            <Typography className={classes.mainText} variant='caption'>Chrome & in-app browsers are not supported on iOS because they're unable to record audio.</Typography>
          </div>
        </div>
      )
    }

    return (
      <div className={classes.main}>
        <AllotoneHeader
          title='Allotone Pronunciation Quiz'
          breadcrumbs={[
            {title: 'Allotone'}
          ]}

        />
        <div className={classes.container}>
          {this.state.step === 'initial' &&
            <React.Fragment>
              <Typography className={classes.mainText} variant='h5'>Let's start with a microphone check</Typography>
              <Button onClick={this.handleClickStart} variant='contained' color='primary'>Get Started</Button>
            </React.Fragment>
          }
          {this.state.step === 'warmup' &&
            <React.Fragment>
              <div className={this.state.wordCompleteStatus !== 'spoken' ? classes.animateIn : classes.animateOut}>
                <Typography className={classes.mainText} variant='h5'>Let's start with a microphone check</Typography>
                <Typography className={classes.subMainText} variant='h5'>Go ahead and say hello, or &quot;nǐhǎo&quot;</Typography>
                <AudioVisualization
                  className={classes.visualizer}
                  getAudioData={this.recorder.mostRecentRecordedChunk}
                  onLoudnessThresholdSurpassed={this.handleLoudnessThresholdSurpassed} />
              </div>
              {(this.state.wordCompleteStatus === 'thinking' || this.state.wordCompleteStatus === 'spoken') &&
                <div className={this.state.wordCompleteStatus === 'thinking' ? classes.animateIn : classes.animateOut}>
                  <Typography className={classes.subMainText} variant='h5'>Great, now let's continue</Typography>
                  <Button
                    variant='contained'
                    color='primary'
                    className={classes.warmupNextButton}
                    onClick={this.completeWarmup}>
                    Next
                  </Button>
                </div>
              }
            </React.Fragment>
          }
          {this.state.step === 'ongoing' &&
            <React.Fragment>
              <div className={[classes.dotsContainer, classes.animateIn].join(' ')}>
                {quizWords.map((dot, i) =>
                  i < this.state.currentWord ?
                  <div key={i} className={classes.darkDot} />
                  :
                  <div key={i} className={classes.lightDot} />
                )}
              </div>
              <Typography className={[classes.mainText, classes.animateIn].join(' ')} variant='h5'>Say this word</Typography>
              <div className={[classes.wordAnimationContainer, classes.animateIn].join(' ')}>
                <div key={this.state.currentWord-1} className={classes.wordContainerLeft}>
                  {this.state.currentWord > 0 &&
                    <Typography
                      className={this.wordCompleteClass()}
                      variant='h5'>
                      {quizWords[this.state.currentWord-1]}
                    </Typography>
                  }
                </div>
                <div key={this.state.currentWord} className={classes.wordContainer}>
                  <Typography
                    className={this.wordCompleteClass()}
                    variant='h5'>
                    {quizWords[this.state.currentWord]}
                  </Typography>
                  <div className={classes.wordCountdown} />
                </div>
                <div key={this.state.currentWord+1} className={classes.wordContainerRight}>
                  {this.state.currentWord+1 < quizWords.length &&
                    <Typography
                      className={this.wordCompleteClass()}
                      variant='h5'>
                      {quizWords[this.state.currentWord+1]}
                    </Typography>
                  }
                </div>
              </div>
              <AudioVisualization
                className={[classes.visualizer, classes.animateIn].join(' ')}
                getAudioData={this.recorder.mostRecentRecordedChunk} />
            </React.Fragment>
          }
          {this.state.step === 'chinese-level' &&
            <ChineseLevelQuestion onComplete={this.handleChineseLevelComplete} />
          }
          {this.state.step === 'enter-email' &&
            <React.Fragment>
              <Typography className={classes.mainText} variant='h5'>You're done! Enter your email to get your results</Typography>
              <TextField
                id="standard-name"
                label="Email"
                type="email"
                name="email"
                autoComplete="email"
                className={classes.textField}
                value={this.state.email}
                onChange={this.handleEmailChange}
                margin="normal"
              />
              <Button
                onClick={this.handleClickSubmitEmail}
                disabled={!emailIsValid(this.state.email)}
                variant='contained'
                color='primary'>
                Submit
              </Button>
            </React.Fragment>
          }
          {this.state.step === 'uploading' &&
            <UploadingVisualization progress={this.state.uploadProgress} />
          }
          {this.state.step === 'checkout' &&
            <React.Fragment>
              <CheckoutComponent
                onFreeCheckoutComplete={this.handleFreeCheckoutComplete} />
            </React.Fragment>
          }
          {this.state.step === 'thanks' &&
            <React.Fragment>
              <Typography className={classes.mainText} variant='h5'>Thanks! Expect an email within 24 hours.</Typography>
              <Typography className={classes.mainText} variant='body1'>If you think a friend could benefit from Allotone, please share!</Typography>
            </React.Fragment>
          }

          {false &&
            <Button onClick={this.advanceToNextWord}>Advance</Button>
          }
        </div>
      </div>
    );
  }
}


PronunciationQuizPage.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withStyles(styles)(PronunciationQuizPage);
