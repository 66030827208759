import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';

import firebase from 'firebase';
import AllotoneHeader from '../views/AllotoneHeader';

/* global navigator */

const styles = theme => ({
  main: {
    paddingBottom: theme.spacing(1),
    overflow: 'scroll',
  },
  container: {
    paddingTop: 'calc(64px + 8px)', // header is 64px on desktop, 56 on mobile
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
});

class GradeQuizPage extends Component {
  state = {
  }

  componentDidMount() {
    firebase.messaging().usePublicVapidKey("BBFqIUMOaGIdWcVVr5jKb_vAajFN6M6p6Hy_JPjW3ycerJQbho4wpP12F6hKjY_-tv0qj0PksOLRSt9rjSTCyUw");
    Notification.requestPermission().then((permission) => {
      this.setState({
        permission: permission,
      })
      if (permission === 'granted') {
        console.log('Notification permission granted.');
        this.getToken();

      } else {
        console.log('Unable to get permission to notify.');
      }
    });
  }

  sendTokenToServer = token => {
    let userRef = firebase.firestore().collection('users').doc(firebase.auth().currentUser.uid);
    console.log('sendTokenToServer');
    
    userRef.set({
      notification: {
        token: token,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        userAgent: navigator.userAgent,
      },
    }, {merge: true});
  }

  getToken = () => {
    firebase.messaging().getToken().then((currentToken) => {
      if (currentToken) {
        this.sendTokenToServer(currentToken);
      } else {
        // Show permission request.
        console.log('No Instance ID token available. Request permission to generate one.');
        // Show permission UI.
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
  }

  render() {
    const classes = this.props.classes;

    return (
      <div className={classes.main}>
        <AllotoneHeader
          title='GradeQuiz'
          breadcrumbs={[
            {title: 'Allotone'}
          ]}

        />

        <div className={classes.container}>
          <Typography variant="h5">Web Push Status</Typography>
          <Typography variant="body2">{this.state.permission}</Typography>
        </div>
      </div>
    );
  }
}


GradeQuizPage.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withStyles(styles)(GradeQuizPage);
