import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import firebase from 'firebase';
import AllotoneHeader from '../views/AllotoneHeader';
import { AsyncStorageData, OnSnapshotController } from '@dokibo/react-firebase-helpers';
import GuessRow from '../components/GuessRow.js';
import {Howl, Howler} from 'howler';

const styles = theme => ({
  main: {
    paddingBottom: theme.spacing(1),
    overflow: 'scroll',
  },
  container: {
    paddingTop: 'calc(64px + 8px)', // header is 64px on desktop, 56 on mobile
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  p: {
    marginBottom: theme.spacing(1),
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '[playButton] 40px [guessPinyin] auto [howSounds] auto',
    gridAutoRows: 'auto',
    alignItems: 'center',
    gridRowGap: theme.spacing(1),
    gridColumnGap: theme.spacing(1),
  },
});

class GradeQuizPage extends Component {
  state = {
  }

  componentDidMount() {
    this.guesses = [];
  }

  dbRef = () => {
    return firebase.firestore()
      .collection('quizzes').doc(this.props.match.params.quizId);
  }

  handleGuessUpdate = i => data => {
    this.guesses[i] = data;
  }

  playSprite = howlId => () => {
    this.howl.play(howlId);
  }

  renderHowler = (data) => {
    if (data && this.howlerSrc !== data.downloadUrl) {
      console.log('creating howl with url & sprite', data.downloadUrl, this.sprite);

      this.howlerSrc = data.downloadUrl;
      this.howl = new Howl({
        src: [data.downloadUrl],
        format: ['wav'],
        sprite: this.sprite,
        onloaderror: (id, code) => {
          console.log('onloaderror', id, code);
        },
        onplayerror: (id, code) => {
          console.log('onplayerror', id, code);
        },
      });
    }

    return null;
  }


  renderQuiz = (data) => {
    const classes = this.props.classes;

    if (data === undefined || data.snapshot === undefined) {
      return null;
    }

    let audioState = data.snapshot.get('audioState');
    if (audioState === 'uploading') {
      return (
        <div>
          <Typography variant="h4">Error: Audio hasn't finished uploading for this quiz</Typography>
        </div>
      )
    }

    let quizWords = data.snapshot.get('quizWords');
    let wordPromptTimes = data.snapshot.get('wordPromptTimes');
    let audioPath = data.snapshot.get('audioPath');

    let rows = [];
    this.sprite = {};

    for (var i=0; i < quizWords.length; i++) {
      let howlId = `word${i}`;

      rows.push(
        <GuessRow
          key={quizWords[i]}
          onPlayClicked={this.playSprite(howlId)}
          onChange={this.handleGuessUpdate(i)}
          dbRef={this.dbRef()
                 .collection('graders').doc(firebase.auth().currentUser.uid)
                 .collection('words').doc(quizWords[i])}
        />
      );

      var end = wordPromptTimes[i+1];
      if (end === undefined) {
        end = data.snapshot.get('audioDuration');
      }
      this.sprite[howlId] = [wordPromptTimes[i], end-wordPromptTimes[i]];
    }

    return (
      <div>
        <div className={classes.grid}>
          <Typography variant="subtitle1"></Typography>
          <Typography variant="subtitle1">pinyin</Typography>
          <Typography variant="subtitle1">qualitative score</Typography>
          {rows}
        </div>
        <AsyncStorageData
          key={audioPath}
          storageRef={firebase.storage().ref(audioPath)}
          >
          {this.renderHowler}
        </AsyncStorageData>
      </div>
    );
  }

  render() {
    const classes = this.props.classes;

    return (
      <div className={classes.main}>
        <AllotoneHeader
          title='GradeQuiz'
          breadcrumbs={[
            {title: 'Allotone'}
          ]}

        />
        
        <div className={classes.container}>
          <Typography variant="h5">Instructions</Typography>
          <Typography variant="body2" className={classes.p}>Listen to each of the audio samples from the student. For each sample, write down, in pinyin, the two characters you hear, e.g. "nin2hao3". Use numbers for tone marks. Please also indicate how native the pronunciation sounded.</Typography>
          <Typography variant="body2" className={classes.p}>Not all of these audio samples will sound like words, and that is ok. Please write down the pinyin you hear, even if it is not a word or is not a word you think the student would know.</Typography>
          <OnSnapshotController
            key={this.dbRef().path}
            dbRef={this.dbRef()}
            >
            {this.renderQuiz}
          </OnSnapshotController>
        </div>
      </div>
    );
  }
}


GradeQuizPage.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withStyles(styles)(GradeQuizPage);
