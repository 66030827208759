import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

/* global Stripe */

var stripe;
var createCheckoutSessionBaseUrl;
if (process.env.REACT_APP_DB === 'dev') {
  stripe = Stripe('pk_test_krdGfC7qsHXkGS6NK4rFSvgi00qjtBznGl');
  createCheckoutSessionBaseUrl = "http://localhost:5001/dokibo-speak-prod/us-central1/createCheckoutSession";
} else if (process.env.REACT_APP_DB === 'prod') {
  stripe = Stripe('pk_live_20kuOQjN35LIP6u5uABJLZP800YHowhbdA');
} else {
  console.error('unknown process.env.REACT_APP_DB', process.env.REACT_APP_DB);
}

const styles = theme => ({
  div: {
    width: '100%',
    maxWidth: '450px',
    textAlign: 'center',
  },
  title: {
  },
  card: {
    width: `calc(100% - ${theme.spacing(2)}px)`,
    backgroundColor: theme.palette.background.default,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  recommendedCardInsert: {
    border: `2px solid ${theme.palette.secondary.main}`,
    borderRadius: theme.shape.borderRadius,
  },
  itemRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  grow: {
    flexGrow: '1',
  },
  priceDiv: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  originalPrice: {
    textDecoration: 'line-through',
    lineHeight: 0.5,
    marginTop: '0.8em',
  },
  finalPrice: {
  },
  cardActions: {
  },
  media: {
    height: 140,
  },
});

class CheckoutComponent extends PureComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  onSelect = reportType => () => {
    console.log('onSelect', reportType);

    if (reportType === 'computer') {
      this.props.onFreeCheckoutComplete && this.props.onFreeCheckoutComplete();
      return;
    }

    fetch(`${createCheckoutSessionBaseUrl}?item=${reportType}`)
    .then(response => response.json())
    .then(sessionJson => {
      console.log('stripe session', sessionJson);

      stripe.redirectToCheckout({
        // Make the id field from the Checkout Session creation API response
        // available to this file, so you can provide it as parameter here
        // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
        sessionId: sessionJson.id
      }).then((result) => {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.

        console.log(result.error.message);
      });
    })

  }

  render() {
    let classes = this.props.classes;

    return (
      <div className={classes.div}>
        <Typography className={classes.title} gutterBottom variant="h5">
          Select Your Report
        </Typography>
        <Card className={classes.card}>
          <CardContent>
            <div className={classes.itemRow}>
              <div className={classes.grow} />
              <Typography variant="h6" component="h6">
                Computer Generated
              </Typography>
              <div className={classes.grow} />
            </div>
            <div className={classes.priceDiv}>
              <Typography className={classes.finalPrice} variant="subtitle1">
                Free
              </Typography>
            </div>
            <CardActions className={classes.cardActions}>
              <div className={classes.itemRow}>
                <div className={classes.grow} />
                <Button size="small" variant='outlined' color="secondary" onClick={this.onSelect('computer')}>
                  Select
                </Button>
                <div className={classes.grow} />
              </div>
            </CardActions>
            <Typography variant="body2" color="textSecondary" component="p">
              An automatic report that shows which words a computer heard correctly and which were misheard.
            </Typography>



          </CardContent>
        </Card>

        <Card className={classes.card}>
          <CardContent className={classes.recommendedCardInsert}>
            <Typography variant="caption">
              Recommended
            </Typography>
            <div className={classes.itemRow}>
              <div className={classes.grow} />
              <Typography variant="h6" component="h6">
                Native Speaker
              </Typography>
              <div className={classes.grow} />
            </div>
            <div className={classes.priceDiv}>
              <Typography className={classes.finalPrice} variant="subtitle1">
                $12
              </Typography>
            </div>
            <CardActions className={classes.cardActions}>
              <div className={classes.itemRow}>
                <div className={classes.grow} />
                <button
                  className="cp-button MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedSecondary MuiButton-ContainedSizeSmall MuiButton-sizeSmall"
                  data-seller="5dca534e8f15800015ce9667"
                  data-checkout="native"
                  >
                  Select
                </button>
                <div className={classes.grow} />
              </div>
            </CardActions>
            <Typography gutterBottom variant="body2" color="textSecondary" component="p">
              A native speaker listens to your words and creates a comprehensive report.
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              An affordable choice for beginner and intermediate students.
            </Typography>
          </CardContent>
        </Card>

        <Card className={classes.card}>
          <CardContent>
            <div className={classes.itemRow}>
              <div className={classes.grow} />
              <Typography variant="h6" component="h6">
                Native Speaker (x3)
              </Typography>
              <div className={classes.grow} />
            </div>
            <div className={classes.priceDiv}>
              <Typography className={classes.originalPrice} color="textSecondary" variant="subtitle1">
                $36
              </Typography>
              <Typography className={classes.finalPrice} variant="subtitle1">
                $24 (33% discount!)
              </Typography>
            </div>
            <CardActions className={classes.cardActions}>
              <div className={classes.itemRow}>
                <div className={classes.grow} />
                  <button
                    className="cp-button MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButton-outlinedSecondary MuiButton-outlinedSizeSmall MuiButton-sizeSmall"
                    data-seller="5dca534e8f15800015ce9667"
                    data-checkout="allotone-report-native-speaker-x3"
                    >
                    Select
                  </button>
                <div className={classes.grow} />
              </div>
            </CardActions>
            <Typography gutterBottom variant="body2" color="textSecondary" component="p">
              3 native speakers with varying accents & hometowns listen to your words.
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              This report is for committed students who want to sound as native as possible.
            </Typography>

          </CardContent>
        </Card>

      </div>
    )
  }
}

CheckoutComponent.propTypes = {
  onFreeCheckoutComplete: PropTypes.func,
};

export default withStyles(styles)(withTheme(CheckoutComponent));
