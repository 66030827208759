import encodeAudioWorker from './workers/encodeAudio.worker';

/* global window */

function WzRecorder(config) {
  config = config || {};

  var self = this;
  var audioInput;
  var audioNode;
  var bufferSize = config.bufferSize || 4096;
  var recordedData = [];
  var recording = false;
  var recordingLength = 0;
  var startDate;
  var audioCtx;
  var recordedChunk = new Float32Array(bufferSize);

  this.toggleRecording = function() {
    recording ? self.stop() : self.start();
  }

  this.start = function() {
    // reset any previous data
    recordedData = [];
    recordingLength = 0;

    // webkit audio context shim
    audioCtx = new (window.AudioContext || window.webkitAudioContext)();

    if (audioCtx.createJavaScriptNode) {
        audioNode = audioCtx.createJavaScriptNode(bufferSize, 1, 1);
    } else if (audioCtx.createScriptProcessor) {
        audioNode = audioCtx.createScriptProcessor(bufferSize, 1, 1);
    } else {
        throw new Error('WebAudio not supported!');
    }

    audioNode.connect(audioCtx.destination);

    navigator.mediaDevices.getUserMedia({audio: true})
      .then(onMicrophoneCaptured)
      .catch(onMicrophoneError);
  };

  this.stop = function() {
    stopRecording(function(blob) {
      self.blob = blob;
      config.onRecordingStop && config.onRecordingStop(blob);
    });
  };

  this.mostRecentRecordedChunk = function() {
    return recordedChunk;
  }

  this.upload = function (url, params, callback) {
    var formData = new FormData();
    formData.append("audio", self.blob, config.filename || 'recording.wav');

    for (var i in params) {
      formData.append(i, params[i]);
    }

    var request = new XMLHttpRequest();
    request.upload.addEventListener("progress", function (e) {
        callback('progress', e, request);
    });

    request.upload.addEventListener("load", function (e) {
        callback('load', e, request);
    });

    request.onreadystatechange = function (e) {
      var status = 'loading';
      if (request.readyState === 4) {
        status = request.status === 200 ? 'done' : 'error';
      }
      callback(status, e, request);
    };

    request.open("POST", url);
    request.send(formData);
  };

  this.exportWavSegment = function(startMillis, endMillies, onCompleteCallback) {
    let startIndex = Math.floor(startMillis / 1000 * audioCtx.sampleRate / bufferSize);
    let endIndex = Math.floor(endMillies / 1000 * audioCtx.sampleRate / bufferSize);
    let segmentLength = bufferSize * (endIndex-startIndex)

    console.log(`exporting wav from ${startMillis}ms (i=${startIndex}) to ${endMillies} (i=${endIndex})`);

    exportWav({
      sampleRate: audioCtx.sampleRate,
      recordingLength: segmentLength,
      data: recordedData.slice(startIndex, endIndex)
    }, function(buffer, view) {
      console.log('exportWavComplete', buffer, view);
      self.blob = new Blob([view], { type: 'audio/wav' });
      onCompleteCallback && onCompleteCallback(self.blob);
    });

  }

  function stopRecording(callback) {
    console.log('stopRecording');
    // stop recording
    recording = false;

    // to make sure onaudioprocess stops firing
    window.localStream.getTracks().forEach( (track) => { track.stop(); });
      audioInput.disconnect();
      audioNode.disconnect();

      if (config.streaming === true) {
        callback && callback();
      } else {
        exportWav({
          sampleRate: audioCtx.sampleRate,
          recordingLength: recordingLength,
          data: recordedData
        }, function(buffer, view) {
          self.blob = new Blob([view], { type: 'audio/wav' });
          callback && callback(self.blob);
        });
      }
    }


  function onMicrophoneCaptured(microphone) {
    // save the stream so we can disconnect it when we're done
    window.localStream = microphone;

    audioInput = audioCtx.createMediaStreamSource(microphone);
    audioInput.connect(audioNode);

    audioNode.onaudioprocess = onAudioProcess;

    recording = true;
    startDate = new Date();

    config.onRecordingStart && config.onRecordingStart();
  }

  function onMicrophoneError(err) {
    console.error('onMicrophoneError', err);

    if (err.name == "NotFoundError" || err.name == "DevicesNotFoundError") {
        //required track is missing
        alert(`Can't find a microphone, maybe try using another device such as your phone?`);
    } else if (err.name == "NotReadableError" || err.name == "TrackStartError") {
        //webcam or mic are already in use
        alert(`Error: Microphone is already in use. Try again after closing other tabs and programs.`);
    } else if (err.name == "OverconstrainedError" || err.name == "ConstraintNotSatisfiedError") {
        //constraints can not be satisfied by avb. devices
        alert(`Something went wrong with the microphone, please reach out to support@allotone.com`);
    } else if (err.name == "NotAllowedError" || err.name == "PermissionDeniedError") {
        //permission denied in browser
        alert(`Can't access microphone, please enable microphone permission for app.allotone.com in your browser's settings`);
    } else if (err.name == "TypeError" || err.name == "TypeError") {
        //empty constraints object
        alert('Error accessing the microphone.')
    } else {
        //other errors
        alert('Error accessing the microphone.')
    }
  }

  function onAudioProcess(e) {
    if (!recording) {
      return;
    }

    recordedChunk = new Float32Array(e.inputBuffer.getChannelData(0));
    self.duration = new Date().getTime() - startDate.getTime();

    if (config.streaming === true) {
      // console.log('recordedChunk.length', recordedChunk.length)
      var recordedBuffer = new ArrayBuffer(recordedChunk.length * 2);
      var view = new DataView(recordedBuffer);

      var index = 0;

      for (var i = 0; i < recordedChunk.length; i++) {
          view.setInt16(index, recordedChunk[i] * 0x7FFF, true);
          index += 2;
      }

      config.onRecording && config.onRecording(self.duration, view);
    } else {
      recordedData.push(recordedChunk);
      recordingLength += bufferSize;

      self.recordingLength = recordingLength;

      config.onRecording && config.onRecording(self.duration);
    }
  }


  function exportWav(config, callback) {
    console.log('exportWav');
    var webWorker = new encodeAudioWorker();
    webWorker.addEventListener('message', event => callback(event.data.buffer, event.data.view));
    webWorker.postMessage(config);
  }
}

export default WzRecorder;
