import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import firebase from 'firebase';

/* global FS */

if (process.env.REACT_APP_DB === 'dev') {
  // TODO: Change dev config to be dev, not prod
  const devConfig = {
    apiKey: "AIzaSyDdPH90HmhYCGK1XrD1W_4MgrI6PQ6JG9Q",
    authDomain: "dokibo-speak-prod.firebaseapp.com",
    databaseURL: "https://dokibo-speak-prod.firebaseio.com",
    projectId: "dokibo-speak-prod",
    storageBucket: "dokibo-speak-prod.appspot.com",
    messagingSenderId: "416885438541",
    appId: "1:416885438541:web:8188f1f986fac856"
  };

  firebase.initializeApp(devConfig);
} else if (process.env.REACT_APP_DB === 'prod') {
  const prodConfig = {
    apiKey: "AIzaSyDdPH90HmhYCGK1XrD1W_4MgrI6PQ6JG9Q",
    authDomain: "dokibo-speak-prod.firebaseapp.com",
    databaseURL: "https://dokibo-speak-prod.firebaseio.com",
    projectId: "dokibo-speak-prod",
    storageBucket: "dokibo-speak-prod.appspot.com",
    messagingSenderId: "416885438541",
    appId: "1:416885438541:web:8188f1f986fac856"
  };

  firebase.initializeApp(prodConfig);
} else {
  console.error('unknown process.env.REACT_APP_DB', process.env.REACT_APP_DB);
}

global.firebase = firebase;

firebase.auth().onAuthStateChanged((user) => {
  if (user === null) {
    return;
  }

  if (user.isAnonymous === false) {
    FS.identify(user.uid, {
      displayName: user.displayName,
      email: user.email,
    });
  }

  var userData = {
    modifiedTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
    email: user.email,
    providerData: user.providerData,
    providerId: user.providerId,
    displayName: user.displayName,
    photoURL: user.photoURL,
    isAnonymous: user.isAnonymous,
  };

  let userPublicData = {
    displayName: user.displayName,
    photoURL: user.photoURL,
  };

  let userRef = firebase.firestore().collection('users').doc(user.uid);
  let userPublicDataRef = userRef.collection('public').doc('userData');

  userRef.get().then(snapshot => {
      console.log('existing user data', snapshot.data());
      console.log('setting data', userData)
      snapshot.ref
        .set(userData, {merge: true})
        .then(result => {
          console.log('updated user', user.uid, userData);
        })
        .catch(error => {
          console.error(error);
        });

      userPublicDataRef.set(userPublicData, {merge: true});
    });
});

ReactDOM.render(
  <App />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
