import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import firebase from 'firebase';
import AllotoneHeader from '../views/AllotoneHeader';
import { AsyncStorageData, OnSnapshotController } from '@dokibo/react-firebase-helpers';
import GuessRow from '../components/GuessRow.js';
import {Howl, Howler} from 'howler';

import IconButton from '@material-ui/core/IconButton';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';

import { ReactTypeformEmbed } from 'react-typeform-embed';

const styles = theme => ({
  main: {
    paddingBottom: theme.spacing(1),
    overflow: 'scroll',
  },
  container: {
    paddingTop: 'calc(64px + 8px)', // header is 64px on desktop, 56 on mobile
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  p: {
    marginBottom: theme.spacing(1),
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '[correctWord] auto [playButton] 40px [guessPinyin] auto',
    gridAutoRows: 'auto',
    alignItems: 'center',
    gridRowGap: theme.spacing(1),
    gridColumnGap: theme.spacing(1),
  },
  toneMatrix: {
    display: 'grid',
    gridTemplateColumns: '[studentTone] auto [graderTone1] auto [graderTone2] auto [graderTone3] auto [graderTone4] auto [graderTone5] auto',
    gridAutoRows: 'auto',
    alignItems: 'center',
    gridRowGap: '1px',
    gridColumnGap: '1px',
    backgroundColor: theme.palette.divider,
    marginBottom: theme.spacing(3),
    border: `1px solid ${theme.palette.divider}`,
  },
  toneMatrixColumnsHeader: {
    gridColumnStart: 2,
    gridColumnEnd: 7,
    backgroundColor: theme.palette.background.default,
    width: `calc(100% - ${theme.spacing(2)}px)`,
    height: `calc(100% - ${theme.spacing(2)}px)`,
    textAlign: 'center',
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  toneMatrixCorrect: {
    backgroundColor: '#D9EAD3',
    width: `calc(100% - ${theme.spacing(2)}px)`,
    height: `calc(100% - ${theme.spacing(2)}px)`,
    textAlign: 'center',
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  toneMatrixYellow: {
    backgroundColor: '#FFF2CC',
    width: `calc(100% - ${theme.spacing(2)}px)`,
    height: `calc(100% - ${theme.spacing(2)}px)`,
    textAlign: 'center',
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  toneMatrixNormal: {
    backgroundColor: theme.palette.background.default,
    width: `calc(100% - ${theme.spacing(2)}px)`,
    height: `calc(100% - ${theme.spacing(2)}px)`,
    textAlign: 'center',
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },

  initialFinalMatrix: {
    display: 'grid',
    gridTemplateColumns: '[intendedWord] auto [graderHeard] auto [comments] auto',
    gridAutoRows: 'auto',
    alignItems: 'center',
    gridRowGap: '1px',
    gridColumnGap: '1px',
    backgroundColor: theme.palette.divider,
    marginBottom: theme.spacing(3),
    border: `1px solid ${theme.palette.divider}`,
  },
});

class GradeQuizPage extends Component {
  state = {
  }

  componentDidMount() {
    this.guesses = [];
  }

  dbRef = () => {
    return firebase.firestore()
      .collection('quizzes').doc(this.props.match.params.quizId);
  }

  graderDbRef = grader => {
    return this.dbRef().collection('graders').doc(grader);
  }

  handleGuessUpdate = i => data => {
    this.guesses[i] = data;
  }

  playSprite = howlId => () => {
    console.log('playSprite', howlId)
    this.howl.play(howlId);
  }

  renderHowler = (data) => {
    if (data && this.howlerSrc !== data.downloadUrl) {
      console.log('creating howl with url & sprite', data.downloadUrl, this.sprite);

      this.howlerSrc = data.downloadUrl;
      this.howl = new Howl({
        src: [data.downloadUrl],
        format: ['wav'],
        sprite: this.sprite,
        onloaderror: (id, code) => {
          console.log('onloaderror', id, code);
        },
        onplayerror: (id, code) => {
          console.log('onplayerror', id, code);
        },
      });
    }

    return null;
  }


  renderQuiz = (data, graderData) => {
    const classes = this.props.classes;

    if (data === undefined || data.snapshot === undefined || graderData === undefined || graderData.snapshot === undefined) {
      return (
        <div>Loading...</div>
      );
    }

    let audioState = data.snapshot.get('audioState');
    if (audioState === 'uploading') {
      return (
        <div>
          <Typography variant="h4">Error: Audio hasn't finished uploading for this quiz</Typography>
        </div>
      )
    }

    let quizWords = data.snapshot.get('quizWords');
    let wordPromptTimes = data.snapshot.get('wordPromptTimes');
    let audioPath = data.snapshot.get('audioPath');

    let rows = [];
    this.sprite = {};

    for (var i=0; i < quizWords.length; i++) {
      let howlId = `word${i}`;

      rows.push(
        <React.Fragment>
          <div>{graderData.snapshot.get('rawResults')[i].student_intended}</div>
          <IconButton onClick={this.playSprite(howlId)} size='small' aria-label="play">
            <PlayCircleFilledIcon />
          </IconButton>
          <div>{graderData.snapshot.get('rawResults')[i].grader_heard}</div>
        </React.Fragment>
      );

      var end = wordPromptTimes[i+1];
      if (end === undefined) {
        end = data.snapshot.get('audioDuration');
      }
      this.sprite[howlId] = [wordPromptTimes[i], end-wordPromptTimes[i]];
    }

    let toneMatrix = graderData.snapshot.get('toneMatrix');
    var toneMatrixRows = [];

    // create header
    toneMatrixRows.push(
      <React.Fragment>
        <div className={classes.toneMatrixNormal}></div>
        <div className={classes.toneMatrixColumnsHeader}>Allotone Heard</div>
      </React.Fragment>
    )

    toneMatrixRows.push(
      <React.Fragment>
        <div className={classes.toneMatrixNormal}></div>
        <div className={classes.toneMatrixNormal}>1st Tone</div>
        <div className={classes.toneMatrixNormal}>2nd Tone</div>
        <div className={classes.toneMatrixNormal}>3rd Tone</div>
        <div className={classes.toneMatrixNormal}>4th Tone</div>
        <div className={classes.toneMatrixNormal}>5th Tone</div>
      </React.Fragment>
    )

    for (var studentTone=1; studentTone <= 5; studentTone++) {
      toneMatrixRows.push(
        <div className={classes.toneMatrixNormal}>Tone {studentTone} intended</div>
      )
      for (var graderTone=1; graderTone <= 5; graderTone++) {
        let numExamples = toneMatrix[studentTone.toString()][graderTone.toString()];

        var className;
        if (graderTone === studentTone) {
          className = classes.toneMatrixCorrect;
        } else if (numExamples > 1) {
          className = classes.toneMatrixYellow;
        } else {
          className = classes.toneMatrixNormal;
        }

        toneMatrixRows.push(
          <div className={className}>{numExamples}</div>
        )
      }
    }


    let initialFinalMatrix = graderData.snapshot.get('initialFinalMatrix');
    var initialFinalRows = [];

    // create header
    initialFinalRows.push(
      <React.Fragment>
        <div className={classes.toneMatrixNormal}>You Intended</div>
        <div className={classes.toneMatrixNormal}>Allotone Heard</div>
        <div className={classes.toneMatrixNormal}>Comments</div>
      </React.Fragment>
    )

    let initialFinalKeys = Object.keys(initialFinalMatrix);
    for (var i=0; i < initialFinalKeys.length; i++) {
      let userSaid = initialFinalKeys[i];

      let graderHeardMatrix = initialFinalMatrix[userSaid];
      let graderHeardKeys = Object.keys(graderHeardMatrix);

      for (var j=0; j< graderHeardKeys.length; j++) {
        let graderHeard = graderHeardKeys[j];

        var className;
        if (userSaid === graderHeard) {
          className = classes.toneMatrixCorrect;
        } else {
          className = classes.toneMatrixNormal;
        }

        initialFinalRows.push(
          <React.Fragment>
            <div className={className}>{userSaid}</div>
            <div className={className}>{graderHeard}</div>
            <div className={classes.toneMatrixNormal}></div>
          </React.Fragment>
        )
      }
    }

    let perfectGuesses = graderData.snapshot.get('perfectGuesses');

    return (
      <div>
        <Typography variant="h6">Summary</Typography>
        <Typography variant="body2" className={classes.p}>
          {perfectGuesses === 0 &&
            "Ouch, Allotone didn't hear any words correctly. Keep Trying!"
          }
          {perfectGuesses === 1 &&
            `Allotone guessed ${perfectGuesses} word perfectly!`
          }
          {perfectGuesses >1 &&
            `Allotone guessed ${perfectGuesses} words perfectly!`
          }
        </Typography>
        <Typography variant="body2" className={classes.p}>
          Allotone heard the tone you tried to say {(graderData.snapshot.get('percentTonesCorrect')*100).toFixed(0)}% of the time
        </Typography>
        <Typography variant="body2" className={classes.p}>
          Allotone heard your initial+final correctly {(graderData.snapshot.get('percentInitialFinalsCorrect')*100).toFixed(0)}% of the time
        </Typography>
        <Typography variant="caption" className={classes.p}>
          Note: This report is computer-generated. <a href="mailto:report-questions@allotone.com">Upgrade</a> to have a native speaker give their review.
        </Typography>


        <Typography variant="h6">Tone Matrix</Typography>
        <div className={classes.toneMatrix}>
          {toneMatrixRows}
        </div>

        <Typography variant="h6">Initial+Final Combinations</Typography>
        <div className={classes.initialFinalMatrix}>
          {initialFinalRows}
        </div>

        <Typography variant="h6">Allotone's Guesses</Typography>
        <Typography variant="body2" className={classes.p}>
          Here are the words you were given and what Allotone heard you said for each word.
        </Typography>
        <div className={classes.grid}>
          <Typography variant="subtitle2">You Intended To Say</Typography>
          <Typography variant="subtitle2"></Typography>
          <Typography variant="subtitle2">Allotone Heard</Typography>
          {rows}
        </div>

        <Typography variant="h6">More</Typography>
        <Typography variant="body2" className={classes.p}>
          Please help us improve Allotone by answering a few questions:
        </Typography>

        <ReactTypeformEmbed
          style={{
            height: '400px',
            width: '100%',
            display: 'block',
            position: 'inherit !important',
          }}
          popup={false}
          url="https://andrewstromme.typeform.com/to/PmwZyz" />

        <AsyncStorageData
          key={audioPath}
          storageRef={firebase.storage().ref(audioPath)}
          >
          {this.renderHowler}
        </AsyncStorageData>
      </div>
    );
  }

  render() {
    const classes = this.props.classes;

    return (
      <div className={classes.main}>
        <AllotoneHeader
          title='GradeQuiz'
          breadcrumbs={[
            {title: 'Allotone'}
          ]}

        />

        <div className={classes.container}>
          <Typography variant="h5">Allotone Report</Typography>
          <Typography variant="body2" className={classes.p}>Thanks for giving Allotone a try!</Typography>
          <Typography variant="body2" className={classes.p}>
            Reach out to <a href="mailto:report-questions@allotone.com">report-questions@allotone.com</a> with any questions you might have.
          </Typography>
          <OnSnapshotController
            key={this.dbRef().path}
            dbRef={this.dbRef()}
            >
            {data =>
              <OnSnapshotController
                key={this.graderDbRef('google').path}
                dbRef={this.graderDbRef('google')}
                >
                {graderData => this.renderQuiz(data, graderData)}
              </OnSnapshotController>
            }
          </OnSnapshotController>
        </div>
      </div>
    );
  }
}


GradeQuizPage.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withStyles(styles)(GradeQuizPage);
