import React, { Component } from 'react';
import PropTypes from 'prop-types';

import guid from './guid.js';
import firebase from 'firebase';

class OnSnapshotController extends Component {
  // usage: must be used with a key=dbRef
  // https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-uncontrolled-component-with-a-key

  constructor(props) {
    super(props);

    this.state = {
      instanceId: guid(),
      localData: {},
    };
  }

  componentDidMount() {
    this.unsubscribeFunction =
    this.props.dbRef &&
    this.props.dbRef.onSnapshot({
      includeMetadataChanges: false,
    }, snapshot => {
      console.log('onSnapshot', snapshot);
      var source = snapshot.metadata.hasPendingWrites ? "Local" : "Server";

      // skip update if it came from this client, because the client already
      // has the latest value cached
      if (source === 'Local' || this.state.instanceId == snapshot.get('_instanceId')) {
        console.log('skipping update that was already cached locally');
        return;
      }

      this.setState({
        localData: snapshot.data() || {},
      });
    }, error => {
      console.error('error while listening to', this.props.dbRef.path, error);
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.dbRef !== this.props.dbRef) {
      console.warn('dbRef shouldn\'t change. Use key=dbRef to reset on dbRef update', prevProps.dbRef, this.props.dbRef)
    }
  }

  componentWillUnmount() {
    this.unsubscribeFunction && this.unsubscribeFunction();
  }

  handleSet = (data, options) => {
    this.props.dbRef.set({
      ...data,
      modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
      _instanceId: this.state.instanceId
    }, options);
    this.setState({
      localData: {...this.state.localData, ...data}
    })
  }

  render() {
    let data = {
      localData: this.state.localData,
      set: this.handleSet,
      props: this.props,
    };

    console.log('OnSnapshotController rendering ref & data', this.props.dbRef, data);
    return this.props.children(data)
  }
}

OnSnapshotController.propTypes = {
  dbRef: PropTypes.object.isRequired,
};

export default OnSnapshotController;
