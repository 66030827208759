import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Helmet from 'react-helmet';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

import dokiboMapIcon from '../dokibo-map-icon.png';

const styles = theme => ({
  main: {},
  grow: {
    flexGrow: 1,
  },
  logo: {
    height: '36px',
    width: '36px',
    marginRight: theme.spacing(1),
  },
  linkDiv: {
    /* https://stackoverflow.com/questions/28362688/css-display-inline-flex-and-text-overflow-ellipsis-not-working-togethier */
    display: 'inline-flex',
  },
  linkCollapser: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100px',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '50px',
    },
  },
  link: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  unstyledLink: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    }
  },
});

class AllotoneHeader extends PureComponent {
  render() {
    let classes = this.props.classes;

    return (
      <div className={classes.main}>
        <Helmet title={this.props.title} />
        <AppBar color='default' position="fixed">
          <Toolbar>
            <a href='/'>
              <img alt='logo' className={classes.logo} src={dokiboMapIcon} />
            </a>
            <Breadcrumbs aria-label="Breadcrumb" className={classes.grow}>
              {this.props.breadcrumbs.map(breadcrumb =>
                (breadcrumb.href === undefined) ?
                  <Typography key={breadcrumb}>
                    {breadcrumb.title}
                  </Typography>
                  :
                  <div className={classes.linkDiv} key={breadcrumb.href}>
                    <Link className={classes.link} color="inherit" href={breadcrumb.href} >
                      {breadcrumb.title}
                    </Link>
                  </div>
              )}

            </Breadcrumbs>
          </Toolbar>
        </AppBar>
      </div>
    )
  }
}

AllotoneHeader.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.array.isRequired,
};

export default withStyles(styles)(AllotoneHeader);
