import WordDb from '../data/word_db.json';

let tone_combos = [
  '11',
  '12',
  '13',
  '14',
  '15',
  '21',
  '22',
  '23',
  '24',
  '25',
  '31',
  '32',
  '33',
  '34',
  '35',
  '41',
  '42',
  '43',
  '44',
  '45',
]

// returns random key from Set or Map
function getRandomKey(collection) {
    let keys = Array.from(Object.keys(collection));
    return keys[Math.floor(Math.random() * keys.length)];
}

function shuffle(array) {
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

export default function(minWords) {
  if (minWords === undefined) {
    minWords = 20;
  }

  let words = [];

  while (words.length < minWords) {
    tone_combos.map(tone_combo => {
      let initialfinal = getRandomKey(WordDb[tone_combo]);
      let possible_pinyins = Object.keys(WordDb[tone_combo][initialfinal]);

      let pinyin = possible_pinyins[Math.floor(Math.random() * possible_pinyins.length)];

      words.push({
        pinyin: pinyin,
        words: WordDb[tone_combo][initialfinal][pinyin],
      });
    });
  }

  return shuffle(words);
}
