import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core/styles';

import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  div: {
    width: '100%',
    maxWidth: '450px',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
  },
  uploadBar: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
});

class UploadingVisualization extends PureComponent {
  state = {
      value: '',
  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  onClickNext = () => {
    this.props.onComplete && this.props.onComplete(this.state.value);
  }

  handleChange = (e) => {
    this.setState({
      value: e.target.value,
    })
  }

  render() {
    let classes = this.props.classes;

    return (
      <div className={classes.div}>
        <Typography className={classes.title} gutterBottom variant="h5">
          Hold tight, your words are still uploading.
        </Typography>
        <Typography className={classes.title} gutterBottom variant="subtitle1">
          Please don't close this page or you may have to start over.
        </Typography>

        <LinearProgress className={classes.uploadBar}
          variant="determinate"
          value={this.props.progress}
          color="secondary" />
      </div>
    )
  }
}

UploadingVisualization.propTypes = {
  progress: PropTypes.number,
};

export default withStyles(styles)(withTheme(UploadingVisualization));
