import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'
import { SnackbarProvider } from 'notistack';

import PronunciationQuizPage from './pages/PronunciationQuizPage';
import GradeQuizPage from './pages/GradeQuizPage';
import WaitlistPage from './pages/WaitlistPage';
import PurchaseCompletePage from './pages/PurchaseCompletePage';
import AdminSetupPush from './pages/AdminSetupPush';
import SignInPage from './pages/SignInPage';
import SpeakingPracticePage from './pages/SpeakingPracticePage';
import QuizResultsPage from './pages/QuizResultsPage';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';

import firebase from 'firebase';


// All the following keys are optional.
// We try our best to provide a great default value.
const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#E8F5E9',
      main: '#E8F5E9',
      dark: '#66BB6A',
      contrastText: 'rgba(0,0,0,0.87)',
    },
    secondary: {
      light: '#81C784',
      main: '#4CAF50',
      dark: '#388E3C',
      contrastText: 'rgba(0,0,0,0.87)',
    },
    error: red,
    // Used by `getContrastText()` to maximize the contrast between the background and
    // the text.
    contrastThreshold: 3,
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  typography: {
    fontSize: 20,
  }
});

console.log('theme', theme);

class PrivateRoute extends Component {
  state = {}

  componentWillMount() {
    this.deregisterFirebaseAuthListener = firebase.auth().onAuthStateChanged(this.setup.bind(this));
  }

  componentWillUnmount() {
    this.deregisterFirebaseAuthListener && this.deregisterFirebaseAuthListener();
  }

  setup(user) {
    this.setState({
      signedIn: user !== null,
      user: user,
    });
  }

  renderComponent = (Component_, props) => {
    console.log('renderComponent', props)
    if (this.state.signedIn === false) {
      return (
        <Redirect
          to={{
            pathname: '/signin',
            search: `?next=${props.location.pathname}`,
            state: { from: props.location }
          }}
        />
      )
    } else {
      return (
        <Component_ {...props} />
      )
    }
  }

  render() {
    const { component: Component_, props, ...rest } = this.props
    if (this.state.signedIn === undefined) {
      return null;
    } else {
      return (
        <Route
          {...rest}
          render={props => this.renderComponent(Component_, props)}
        />
      );
    }
  }
}

class App extends Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <SnackbarProvider
          anchorOrigin={{
               vertical: 'top',
               horizontal: 'center',
           }}
          maxSnack={3}>
        <Router>
          <Switch>
            <Route path="/signin" component={SignInPage}/>
            <PrivateRoute path="/quizzes/:quizId" component={GradeQuizPage} />
            <Route path="/quiz-results/:quizId" component={QuizResultsPage} />
            <Route path="/speaking-practice" component={SpeakingPracticePage} />
            <Route path="/waitlist" component={WaitlistPage} />
            <PrivateRoute path="/admin/setup-push" component={AdminSetupPush} />
            <Route path="/payment-success" component={PurchaseCompletePage} />
            <Route path="/" component={PronunciationQuizPage} />
          </Switch>
        </Router>
        </SnackbarProvider>
      </MuiThemeProvider>
    );
  }
}

export default App;
